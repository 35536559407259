<template>
    <div>
      <Card>
        <template #header>Teklifler</template>
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <Input placeholder="Ara.." v-model="search" class="mr-2" />
          </div>
          <div class="d-flex align-items-center">
            <b-form-group>
              <b-button variant="primary" @click="addFileModal=true">İhale Süresini Değiştir</b-button></b-form-group
            >
          </div>
        </div>
      </Card>
  
      <List
        :header="header"
        :data="items"
        :pagination="pagination"
        :loading="loading"
        @updatePage="updatePage"
        moduleName="Sistem Kullanıcı Listesi"
        :moduleCreateEvent="true"
        :moduleSearch="search"
      >
        
        <template v-slot:item.action="{ item }">
          <div class="d-flex">
            <button class="btn p-0">
              <i class="flaticon2-trash text-dark text-md"></i>
            </button>
          </div>
        </template>
      </List>
      <b-modal v-model="addFileModal" id="note-modal">
      <template #modal-header>
        <h4><i class="flaticon2-plus mr-4"></i>İhale Süresini Değiştir</h4>
      </template>
      
      <div class="form-group">
        <label for="type">Tarih</label>
        <p>12 Temmuz 2023 | 11:17</p>
      </div>
      <div class="form-group">
        <label for="type">Güncelleme Tarihi </label>
        <input type="date" class="form-control">
      </div>

      <template #modal-footer>
        <div class="d-flex justify-content-end w-100">
          <b-button variant="light" @click="addFileModal = false">
            Vazgeç
          </b-button>
          <b-button variant="success" @click="addNewNote" class="ml-4">
            Kaydet
          </b-button>
        </div>
      </template>
    </b-modal>
    </div>
  </template>
  
  <script>
  import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
  
  export default {
    name: "systemUsers",
    data() {
      return {
        addFileModal:false,
        search: "",
        filters: "",
        showInsertUpdate: false,
        showDetail: false,
        showImportModal: false,
        resetForm: false,
        confirmCreate: false,
        loading: false,
        header: [
          { text: "Teklif Sahibi", value: "teklifsahibi" },
          { text: "Teklif Tutarı", value: "fiyat" },
          { text: "Başlangıç Tarihi	", value: "baslangic" },
          { text: "Bitiş Tarihi", value: "bitis" },
        ],
        items: [],
        pagination: {},
        meta: {},
        listSelectedItemIds: [],
        selectedItem: "",
        deleteOperationUrl: "",
        deleteIds: "",
      };
    },
    
    created() {
      this.getList();
    },
    methods: {
      getList() {
        this.loading = true;
  
        this.items = [
          {
            id: 1,
            teklifsahibi: "Ahmet Berke Aydın",
            fiyat:"500₺",
            baslangic:"25.07.2023",
            bitis:"10.08.2023"
          },
          {
            id: 1,
            teklifsahibi: "Rasim Karadeniz",
            fiyat:"100₺",
            baslangic:"25.07.2023",
            bitis:"10.08.2023"
          },
          {
            id: 1,
            teklifsahibi: "Nisa Karabacak",
            fiyat:"700₺",
            baslangic:"25.07.2023",
            bitis:"10.08.2023"
          },
          {
            id: 1,
            teklifsahibi: "Melisa Eldem",
            fiyat:"1000₺",
            baslangic:"25.07.2023",
            bitis:"10.08.2023"
          },
        ];
        this.totalItems = 3;
  
        this.pagination = {
          current_page: 1,
          total_pages: 1,
          total_items: 3,
        };
        this.loading = false;
  
        // this.items = [];
        // this.totalItems = 0;
  
        // let queryLimit = this.perPage ? this.perPage : 10;
        // let query = `user/list?Page=${
        //   this.page ? this.page : 1
        // }&limit=${queryLimit}`;
        // if (this.search) {
        //   query += `&search=${this.search}`;
        // }
  
        // this.$dbFunctions
        //   .get(query)
        //   .then((res) => {
        //     if (res.success == true && res.count > 0) {
        //       this.items = res.data;
        //       this.totalItems = res.count;
  
        //       this.pagination = {
        //         current_page: res.page,
        //         total_pages: res.pageCount,
        //         total_items: res.count,
        //       };
        //     }
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   })
        //   .finally(() => {
        //     this.loading = false;
        //   });
      },
      updatePage(value) {
        this.meta = value;
  
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },
    },
    watch: {
      meta: {
        handler() {
          this.getList();
        },
        deep: true,
      },
      search: function (params) {
        this.getList();
      },
    },
  };
  </script>
  